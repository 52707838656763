const SidebarContactUs = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      fill="none"
      viewBox="0 0 22 21"
    >
      <path
        fill="url(#paint0_linear_1330_32398)"
        d="M7.483 5.088H20.4l-7.056 5.54a.943.943 0 01-.488.19.961.961 0 01-.492-.19L7.32 6.668a.539.539 0 00-.18-.097c.244-.343.373-.755.365-1.176a2.828 2.828 0 00-.023-.307zM4.2 1.525a.491.491 0 01.1.04c.116.054.298.195.477.39.36.392.72.986.906 1.378a.677.677 0 01.017.037c.53.91.759 1.605.766 2.04.007.439-.11.627-.482.84l-.958.553c-.624.36-.92 1.003-.992 1.604-.074.601.034 1.185.302 1.647L6.7 14.13c.268.462.725.848 1.284 1.085.56.237 1.268.305 1.892-.054l.958-.551c.371-.213.596-.217.973.007.378.224.868.767 1.396 1.676a.51.51 0 00.023.038c.247.357.584.962.747 1.468.08.252.112.48.102.605-.011.127-.014.114-.048.133l-.729.418c-1.831.795-3.51.615-5.01-.163-1.503-.781-2.81-2.192-3.72-3.849l-.007-.01-1.995-3.438c-.004-.003-.005-.006-.008-.01C1.571 9.87.996 8.039 1.068 6.354c.072-1.683.755-3.217 2.364-4.397l.729-.417c.017-.01.022-.017.038-.015zM4.206.5a1.087 1.087 0 00-.567.14l-.77.444-.05.03C.964 2.472.11 4.362.026 6.31c-.083 1.947.567 3.958 1.641 5.715l1.989 3.427-.005-.01c.993 1.803 2.415 3.368 4.153 4.27 1.74.902 3.81 1.111 5.92.191a.393.393 0 00.051-.025l.773-.444c.357-.203.536-.598.566-.944.03-.344-.04-.679-.147-1.009-.209-.653-.573-1.287-.873-1.722-.569-.977-1.117-1.655-1.75-2.034a1.997 1.997 0 00-2.032-.016l-.958.552c-.283.163-.614.144-.962-.003-.347-.147-.668-.442-.787-.648L5.24 9.535c-.12-.208-.218-.631-.171-1.005.044-.372.195-.664.479-.827l.93-.535a.515.515 0 00.198.314l5.042 3.961a1.98 1.98 0 001.107.424h.06c.404-.025.79-.173 1.107-.424l6.944-5.455V15c0 .138-.156.318-.463.318h-4.807a.52.52 0 100 1.039h4.807c.786 0 1.508-.568 1.508-1.356V5.405c0-.788-.722-1.356-1.508-1.356H7.197a9.252 9.252 0 00-.585-1.18c-.23-.477-.6-1.107-1.065-1.613C5.313.999 5.06.772 4.743.625v.001A1.34 1.34 0 004.206.5z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1330_32398"
          x1="0.652"
          x2="2.67"
          y1="1.522"
          y2="22.711"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2AEBEB"></stop>
          <stop offset="0.45" stopColor="#6174FF"></stop>
          <stop offset="0.75" stopColor="#B63CFB"></stop>
          <stop offset="1" stopColor="#FF47E3"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SidebarContactUs;
