const LogoutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#E4E6FF"
        d="M10 21v-.75.75zm-1 0v.75V21zm-6-6h.75H3zm0-6h-.75H3zm6-6v-.75V3zm1 0v.75V3zM3.062 7.218L2.32 7.1l.74.117zm4.156-4.156l.117.74-.117-.74zm0 17.876l-.117.741.117-.74zm-4.156-4.156l-.741.117.74-.117zM13.45 20.6a.75.75 0 00-.9-1.2l.9 1.2zm-.9-16a.75.75 0 10.9-1.2l-.9 1.2zM7 11.25a.75.75 0 000 1.5v-1.5zM20 12v.75V12zm-2.534-4.588a.75.75 0 00-.932 1.176l.932-1.176zm1.297 1.986l.466-.588-.466.588zm0 5.204l.466.588-.466-.588zm-2.229.81a.75.75 0 00.932 1.176l-.932-1.176zm4.446-3.663l.74-.118-.74.118zm0 .502l.74.118-.74-.118zM21 12h.75H21zm-11 8.25H9v1.5h1v-1.5zM3.75 15V9h-1.5v6h1.5zM9 3.75h1v-1.5H9v1.5zM3.75 9c0-.958.002-1.35.052-1.665l-1.481-.234C2.248 7.562 2.25 8.1 2.25 9h1.5zM9 2.25c-.9 0-1.438-.002-1.9.07l.235 1.482c.316-.05.707-.052 1.665-.052v-1.5zM3.802 7.335a4.25 4.25 0 013.533-3.533l-.234-1.481A5.75 5.75 0 002.32 7.1l1.481.234zM9 20.25c-.958 0-1.35-.002-1.665-.052l-.234 1.481c.461.073.999.071 1.899.071v-1.5zM2.25 15c0 .9-.002 1.438.07 1.9l1.482-.235c-.05-.316-.052-.707-.052-1.665h-1.5zm5.085 5.198a4.25 4.25 0 01-3.533-3.533l-1.481.235a5.75 5.75 0 004.78 4.78l.234-1.482zM10 21.75c1.294 0 2.49-.428 3.45-1.15l-.9-1.2c-.71.534-1.592.85-2.55.85v1.5zm0-18c.958 0 1.84.316 2.55.85l.9-1.2A5.728 5.728 0 0010 2.25v1.5zm-3 9h13v-1.5H7v1.5zm9.534-4.162l1.763 1.398.932-1.176-1.763-1.398-.932 1.176zm1.763 5.427l-1.763 1.397.932 1.176 1.763-1.398-.932-1.175zm0-4.03c.717.57 1.204.957 1.532 1.283.324.323.393.493.41.6l1.482-.237c-.092-.573-.428-1.021-.834-1.425-.402-.401-.97-.85-1.658-1.396l-.932 1.176zm.932 5.205c.689-.546 1.256-.995 1.659-1.396.405-.404.741-.852.833-1.425l-1.482-.236c-.017.106-.086.276-.41.599-.328.326-.815.714-1.532 1.283l.932 1.175zm1.01-3.323a.85.85 0 01.011.133h1.5c0-.123-.01-.247-.03-.369l-1.48.236zm.011.133a.85.85 0 01-.01.133l1.48.236c.02-.122.03-.246.03-.369h-1.5zm-.25.75h1v-1.5h-1v1.5z"
      ></path>
    </svg>
  );
};

export default LogoutIcon;
