import React from "react";

const InfoCircle = () => {
  return (
    <svg
      width={44}
      height={44}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333984 21.9987C0.333984 10.0409 10.0407 0.332031 22.0006 0.332031C33.9823 0.332031 43.6673 10.0409 43.6673 21.9987C43.6673 33.9609 33.9823 43.6654 22.0006 43.6654C10.0407 43.6654 0.333984 33.9609 0.333984 21.9987ZM20.094 13.787C20.094 12.7492 20.9607 11.8804 22.0007 11.8804C23.0407 11.8804 23.8857 12.7492 23.8857 13.787V23.3637C23.8857 24.4059 23.0407 25.2487 22.0007 25.2487C20.9607 25.2487 20.094 24.4059 20.094 23.3637V13.787ZM22.0223 32.1409C20.9607 32.1409 20.1157 31.2742 20.1157 30.2342C20.1157 29.1942 20.9607 28.3492 22.0007 28.3492C23.0623 28.3492 23.9073 29.1942 23.9073 30.2342C23.9073 31.2742 23.0623 32.1409 22.0223 32.1409Z"
        fill="#D75C5C"
      />
    </svg>
  );
};

export default InfoCircle;
