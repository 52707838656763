const VerifiedProfile = ({ width = 10, height = 10 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 10 10"
    >
      <path
        fill="#E4E6FF"
        d="M3.93.073c.056.019.11.041.163.067l.634.312a.618.618 0 00.546 0L5.908.14a1.36 1.36 0 011.82.62l.036.08.031.082.228.669a.618.618 0 00.386.386l.67.228a1.36 1.36 0 01.781 1.888l-.312.634a.618.618 0 000 .546l.312.635a1.36 1.36 0 01-.782 1.887l-.67.228a.618.618 0 00-.385.386l-.228.67a1.36 1.36 0 01-1.888.781l-.634-.312a.618.618 0 00-.546 0l-.634.312a1.36 1.36 0 01-1.888-.782l-.227-.669a.618.618 0 00-.387-.386l-.669-.228A1.36 1.36 0 01.14 5.908l.312-.635a.618.618 0 000-.546L.14 4.093a1.36 1.36 0 01.782-1.888l.669-.227a.618.618 0 00.386-.387l.228-.669A1.36 1.36 0 013.93.072zm2.786 3.429l-2.68 2.68L3.06 5.01a.37.37 0 10-.57.474l1.236 1.484a.37.37 0 00.547.025L7.24 4.026a.37.37 0 10-.524-.524"
      ></path>
    </svg>
  );
};

export default VerifiedProfile;
