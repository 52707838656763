import { Modal } from "antd";
// import warningAction from "../../assets/images/svg/warning-action.svg";
import { Dispatch, SetStateAction } from "react";
import WarningAction from "../../assets/images/svg/warning-action";

const UnderVerification = ({
  verificationModal,
  setVerificationModal,
}: {
  verificationModal: boolean;
  setVerificationModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const handleOk = () => {
    setVerificationModal(false);
  };

  const handleCancel = () => {
    setVerificationModal(false);
  };
  const goBackHandler = () => {
    setVerificationModal(false);
  };
  return (
    <>
      <Modal
        centered
        className="common-modal modal-bottom sm:!max-w-[450px]"
        open={verificationModal}
        onOk={handleOk}
        closable={false}
        onCancel={handleCancel}
        width={450}
      >
        <div className="bg-primary flex flex-col justify-center items-center rounded-[18px]">
          <div className="flex flex-col justify-center items-center border border-grey w-full h-[293px] sm:h-[308px] rounded-[18px] space-y-1.5 p-2.5 text-center">
            <div>
              <WarningAction />
            </div>
            <h1 className="text-lightWhite text-[20px] sm:text-[24px] font-semibold">
              Can’t Perform Action
            </h1>

            <p className="text-[16px] sm:text-[18px] font-normal text-blue max-w-xs">
              You can perform any actions once the Profile verification process
              is complete.
            </p>

            <button
              onClick={goBackHandler}
              className="w-44 h-11 !mt-8 border border-primary rounded-4xl rounded-1 hover:bg-white hover:text-black font-semibold text-white text-base"
            >
              Go Back
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default UnderVerification;
