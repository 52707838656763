const WarningAction = ({ width = 10, height = 10 }) => {
  return (
    <svg
      width={52}
      height={52}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33398 25.9987C4.33398 14.0409 14.0407 4.33203 26.0006 4.33203C37.9823 4.33203 47.6673 14.0409 47.6673 25.9987C47.6673 37.9609 37.9823 47.6654 26.0006 47.6654C14.0407 47.6654 4.33398 37.9609 4.33398 25.9987ZM24.094 17.787C24.094 16.7492 24.9607 15.8804 26.0007 15.8804C27.0407 15.8804 27.8857 16.7492 27.8857 17.787V27.3637C27.8857 28.4059 27.0407 29.2487 26.0007 29.2487C24.9607 29.2487 24.094 28.4059 24.094 27.3637V17.787ZM26.0223 36.1409C24.9607 36.1409 24.1157 35.2742 24.1157 34.2342C24.1157 33.1942 24.9607 32.3492 26.0007 32.3492C27.0623 32.3492 27.9073 33.1942 27.9073 34.2342C27.9073 35.2742 27.0623 36.1409 26.0223 36.1409Z"
        fill="#FFD600"
      />
    </svg>
  );
};

export default WarningAction;
