import { FC, Suspense } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Layout } from "./layout/layout";
import Loader from "./components/common/loader";
import Router from "./router";
import { notification } from "antd";
const App: FC = () => {
  const [api, contextHolder] = notification.useNotification();
  return (
    <Layout>
      {contextHolder}
      <Suspense fallback={<Loader note="from APP suspense" />}>
        <Router api={api} />
      </Suspense>
    </Layout>
  );
};

export default App;
